// @ts-ignore
import {
  closeAuthModal,
  getOrderInvoiceURL,
  getOrderTracking,
  getTags,
  loadItemsEditOrder,
  openUpdateOrderStatus,
  redirect,
  setOpenAuthModal,
  setUpdateTagsVisible,
  showExportedPDF,
  triggerInvoicing,
  triggerOrderEvent,
  updateSelectedTags,
} from '@actions/index';

import { useLazyFindOrdersQuery } from '@api/query/orderApi/orderApi';
import { FindOrdersRequest, Order } from '@api/query/orderApi/types';
import { PaginationQuery } from '@api/query/types';
import {
  cellsById,
  DataGrid,
  DataGridRowContent,
  DataGridToolbar,
  GlobalFilterCallback,
  HighlightText,
} from '@components/DataGrid';
import AuthModalComponent from '@components/Modals/Auth';
import ModalConsolidatedBilling from '@components/Modals/invoicing/ModalConsolidatedBilling';
import ModalCreditNote from './components/ModalCreditNote';
import ModalMultipleBilling from '@components/Modals/invoicing/ModalMultipleBilling';
import TagFigure from '@components/Order/TagFigure';
import PageHeader from '@components/PageHeader';
import UpdateOrderStatusContainer from '@containers/OrderTracking/UpdateOrderStatusContainer';
import { useLazyPagination } from '@containers/PriceList/UseLazyPagination';
import { More as MoreIcon } from '@rsuite/icons';
import InfoIcon from '@rsuite/icons/InfoRound';
import PlusIcon from '@rsuite/icons/Plus';
import UpdateIcon from '@rsuite/icons/UpdateRound';
import {
  getTagsList,
  getUpdateTagsVisible,
} from '@selectors/OrderTagsSelector';
import { getStatusList } from '@selectors/StatusSelector';
import { getStatusColor } from '@utils/DataFormat';
import dayjs, { dayjsCalendar, dayjsRanges, formats } from '@utils/dayjs';
import { isFetchBaseQueryError } from '@utils/queryUtils';
import * as reports from '@utils/ReportsBuilder/ShippingList';
import { ExportToCsv } from 'export-to-csv';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaFileExcel, FaFileInvoiceDollar, FaFilePdf } from 'react-icons/fa';
import {
  FormattedMessage,
  FormattedNumberParts,
  FormattedPlural,
  useIntl,
} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import {
  CheckPicker,
  DateRangePicker,
  Divider,
  Dropdown,
  IconButton,
  Input,
  Message,
  Popover,
  Tag,
  toaster,
  Toggle,
  Tooltip,
  Whisper,
} from 'rsuite';
import { getLoggedUser } from '../../selectors/AuthorizationSelector';
import {
  getOrderTrackingData,
  getOrderTrackingVisible,
  getShowExportedPDF,
  getUpdateOrderStatusVisible,
} from '../../selectors/OrderTrackingSelector';
import {
  getAuthModalData,
  getMainLoggingParams,
  validateUpdateOrders,
  validatepermissions,
} from '../../selectors/SystemSelector';
import * as constants from '../../utils/Constants';
import { getCSVOrderOptions, WINDOW_FEATURES } from '../../utils/Constants';
import { getOutputExcelOrders } from '../../utils/Mappers/OrderMappers';
import { EXPORT_SELECT_ORDER } from '../../utils/Notifications';
import OrderTrackingContainer from '../OrderTracking/OrderTrackingContainer';
import AssignOrderTagContainer from './AssignOrderTagContainer';
import ToggleButton from 'rsuite/esm/Picker/ToggleButton';
import { useFindPaymentMethodQuery } from '@api/query/paymentMethod';
import { useFindChannelQuery } from '@api/query/channelApi';
import { useLocalstorageState } from 'rooks';
import TotalUnits from '@components/Dashboards/TotalUnits';
import { ModalExportData } from "@components/Modals/ModalExportData";
import { useDownloadDataMutation } from '@api/query/orderApi/orderApi';

type InvoiceParams = {
  action: string;
  orderId: string;
  statusId: string;
  statusAction?: string;
  originalData?: unknown;
};

type CompanyData = {
  inventoryConfig?: { controlsInventory: boolean, allowsNegativeSale: boolean }
}

const ListOrders: React.FC = () => {
  const { $t } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
  companyId,
  fulfillmentLocationId,
  permissions,
  deliveryHoursAhead,
  maxNumberDownloadAllowed,
  companyData,
  ...loggingParamsRest
} = useSelector(getMainLoggingParams) as { companyData?: CompanyData } & Record<string, any>;

  useEffect(() => {
    if (validatepermissions(permissions, 'MENU_ORDERS')) {
      history.push({ pathname: '/home' });
    }
  }, []);

  const orderTrackingVisible = useSelector(getOrderTrackingVisible);
  const updateStatusContainerVisible = useSelector(getUpdateOrderStatusVisible);
  const authModalVisible = useSelector(getAuthModalData);
  const loggedUser = useSelector(getLoggedUser);
  const statusList = useSelector(getStatusList);
  const orderTrackingData = useSelector(getOrderTrackingData);
  const tagsList = useSelector(getTagsList);
  const [openExportData, setOpenExportData] = useState(false);
  const [extraQueryValues, setExtraQueryValues] = useState({});
  const pdfExportVisible = useSelector(getShowExportedPDF);
  const updateTagsVisible = useSelector(getUpdateTagsVisible);
  const [modalConsolidatedBillingOpen, setModalConsolidatedBillingOpen] =
    useState(false);
  const [modalMultipleBillinggOpen, setModalMultipleBillingOpen] =
    useState(false);
  
  const [openModalCreditNote, setOpenModalCreditNote] = useState(false);
  const [orderActive, setOrderActive] = useState<Order>();

  const paymentMethods = useFindPaymentMethodQuery({});
  const channels = useFindChannelQuery({});

  const handleOnClickExportCSV = () => {
		if (virtualArray?.ref?.length > 0) {
			setOpenExportData(true)
		} else {
			toaster.push(
				<Message
					type="error"
					showIcon
					closable
					duration={constants.NOTIFICATION_DURATION}
				>
					{'La consulta seleccionada no arroja datos.'}
				</Message>
			);
		}
  };
	const submitExport = (data) => {
		const body = { ...data, ...extraQueryValues }
		setDownload(body)
			.then(resp => {
			try {
				const menssageError = resp
				if (menssageError) {
					toaster.push(
						<Message
							type="success"
							showIcon
							closable
							duration={constants.NOTIFICATION_DURATION}
						>
							{'El archivo se está generando y se enviará un correo electrónico con un link de descarga.'}
						</Message>
					);
				}
			} catch (error) {
				console.error('Error en la promesa:', error);
			}
		})
  };

  useEffect(() => {
    orderTrackingData.length && pdfExportVisible && exportOrdersToPDF();
  }, [orderTrackingData]);

  useEffect(() => {
    dispatch(getTags(companyId));
  }, []);

  const handleOnClickPDF = (checkedOrders: Record<string, Order>) => {
    dispatch(showExportedPDF(true));

    const selectedOrders = Object.values(checkedOrders).map(order => order.id);
    dispatch(
      getOrderTracking({
        populateData: true,
        action: 'orderExport',
        orderIds: selectedOrders,
      }),
    );
  };

  const [ordersSelected, setOrdersSelected] = React.useState(null);

  const handleOnClickBilling = (orders: Record<string, Order>) => {
    let flag = true;
    const selectedOrders = Object.values(orders);
    if (selectedOrders?.length === 0) {
      flag = false;
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}>
          {EXPORT_SELECT_ORDER}
        </Message>,
      );
    }
    if (selectedOrders?.length > 85 && selectedOrders?.length !== 0) {
      flag = false;
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}>
          {'Debes seleccionar un máximo de 85 ordenes'}
        </Message>,
      );
    } else {
      for (let i = 0; i < selectedOrders?.length; i += 1) {
        if (
          selectedOrders[i]?.customer?.id !==
          selectedOrders[i + 1]?.customer?.id &&
          selectedOrders[i + 1]?.customer?.id !== undefined
        ) {
          flag = false;
          toaster.push(
            <Message
              type="warning"
              showIcon
              closable
              duration={constants.NOTIFICATION_DURATION}>
              {'Solo puedes facturar ordenes de un mismo cliente'}
            </Message>,
          );
          break;
        } else if (selectedOrders[i]?.invoiceId !== null && selectedOrders[i]?.invoiceId !== undefined) {
          console.log(selectedOrders[i])
          flag = false;
          toaster.push(
            <Message
              type="warning"
              showIcon
              closable
              duration={constants.NOTIFICATION_DURATION}>
              {'⚠ La(s) orden(es) ya han sido facturadas!'}
            </Message>,
          );
          break;
        }
      }
    }
    if (flag) {
      setOrdersSelected(selectedOrders);
      setModalConsolidatedBillingOpen(true);
    }
  };

  const handleOnClickMultiple = (orders: Record<string, Order>) => {
    let flag = true;
    const selectedOrders = Object.values(orders);
    if (selectedOrders?.length === 0) {
      flag = false;
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}>
          {EXPORT_SELECT_ORDER}
        </Message>,
      );
    }
    if (selectedOrders?.length > 15 && selectedOrders?.length !== 0) {
      flag = false;
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}>
          {'Debes seleccionar un máximo de 15 ordenes'}
        </Message>,
      );
    }
    if (flag) {
      setOrdersSelected(selectedOrders);
      setModalMultipleBillingOpen(true);
    }
  };

  const onCloseModalConsolidatedBilling = () => {
    setModalConsolidatedBillingOpen(false);
  };

  const onCloseModalCreditNote = () => {
    setOpenModalCreditNote(false);
  };

  const onCloseModalMultipleBilling = () => {
    setModalMultipleBillingOpen(false);
  };

  const exportOrdersToPDF = async () => {
    const filteredData = orderTrackingData.map(order => ({
      ...order,
      orderItems: order.orderItems.filter(item => item.active === true)
    }))
    
    const doc = await reports.buildShippingList(
      filteredData,
      companyId,
      permissions,
    );
    reports.downloadShippingList(doc, null);
    dispatch(showExportedPDF(false));
  };

  const getDocumentCreationStatus = type => {
    const status = statusList.find(status => status.role === type);
    return status.id;
  };

  const redirectCreateOrder = () => dispatch(redirect('create-order'));

  const handleCloseModal = () => {
    dispatch(closeAuthModal());
    dispatch(setOpenAuthModal({ open: false }));
    dispatch(redirect('/logout'));
    window.location.reload();
  };

  const [filterByStatus, setFilterByStatus] = useLocalstorageState(
    'filterByStatus',
    true,
  );

  const [triggerFindOrdersQuery, result] = useLazyFindOrdersQuery();

  const trigger = useCallback(
    (value: FindOrdersRequest) => {
      return triggerFindOrdersQuery({
        ...value,
        ...(value.actives
          ? {
            orderBy: value.orderBy || 'remainingTime:ASC',
          }
          : {
            orderBy: value.orderBy || 'createdAt:DESC',
          }),
      });
    },
    [triggerFindOrdersQuery],
  );

  const { virtualArray, setViewRange, setExtraQuery, resetPagination } =
    useLazyPagination({
      trigger,
      result,
      initialLimit: 100,
    });

  const data = result.data;

  useEffect(() => {
    const unsubscribe = setExtraQuery(newValues => {
      setExtraQueryValues(newValues);
    });

    return () => unsubscribe
	}, [setExtraQuery]);

  const [setDownload] = useDownloadDataMutation();

  const statusOptions = useMemo(
    () =>
      statusList.map(value => ({
        value: value.id,
        label: value.description,
      })),
    [statusList],
  );

  const paymentOptions = useMemo(() => {
    return (paymentMethods.data?.items || []).map(item => ({
      label: item.description,
      value: item.id,
    }));
  }, [paymentMethods.data]);

  const channelOptions = useMemo(() => {
    return (channels.data?.items || []).map(item => ({
      label: item.description,
      value: item.id,
    }));
  }, [channels.data]);

  const tagOptions = useMemo(
    () => tagsList.map(tag => ({ value: tag.label, label: tag.label })),
    [tagsList],
  );

  const globalFilter = useCallback<GlobalFilterCallback<Order>>(
    (rows, columnIds, filterValue: string) => {
      setExtraQuery(value => ({
        ...value,
        keyword: filterValue.trim() ? filterValue : undefined,
      }));
      setExtraQueryValues(prevValues => ({
        ...prevValues,
        keyword: filterValue.trim() ? filterValue : undefined,
      }));
      return rows;
    },
    [],
  );

  const pendingStatusList = useMemo<string[]>(
    () =>
      statusList
        .filter(item => {
          return !['CANCEL', 'DELIVERY'].includes(item.role);
        })
        .map(item => item.id),
    [statusList],
  );

  const columns = useMemo<Column<Order>[]>(
    (): Column<Order>[] => [
      {
        id: 'orderNumber',
        Header: (
          <span className="whitespace-nowrap">
            <FormattedMessage id="ordox" />
          </span>
        ),
        accessor: 'orderNumber',
        sticky: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
          }));
        },
        Cell: ({ value, state }) => (
          <div className="text-center">
            <HighlightText text={value} subtext={state.globalFilter} />
          </div>
        ),
        filter: (row, _, filterValue) => {
          filterValue = `${filterValue}`;

          setExtraQuery(value => ({
            ...value,
            orderNumber: filterValue.trim() ? filterValue : undefined,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderNumber: filterValue.trim() ? filterValue : undefined,
          }));
          return row;
        },
      },
      {
        id: 'client',
        Header: <FormattedMessage id="client" />,
        accessor: row => row.customer.fullName,
        width: 300,
        maxWidth: 300,
        minWidth: 300,
        sticky: true,
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `customer.fullName:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind
              ? undefined
              : `customer.fullName:${kind.toUpperCase()}`,
          }));
        },
        filter: (row, _, filterValue) => {
          filterValue = `${filterValue}`;

          setExtraQuery(value => ({
            ...value,
            customerName: filterValue.trim() ? filterValue : undefined,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            customerName: filterValue.trim() ? filterValue : undefined,
          }));
          return row;
        },
      },
      {
        id: 'status',
        Header: <FormattedMessage id="state" />,
        accessor: row => row.status.description,
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        sticky: true,
        onToggleSortBy(kind, columnId) {
          if (kind) setFilterByStatus(false);

          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `${columnId}.description:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind
              ? undefined
              : `${columnId}.description:${kind.toUpperCase()}`,
          }));
        },
        Cell: ({ value, state, row }) => (
          <Tag
            color={getStatusColor(
              row.original.remainingTime,
              row.original.status,
            )}
            size="sm">
            <HighlightText text={value} subtext={state.globalFilter} />
          </Tag>
        ),
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={statusOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          if ((filterValue?.length || 0) > 0) {
            const actives = pendingStatusList.every(id =>
              filterValue?.includes(id),
            );
            setExtraQuery(value => ({
              ...value,
              status: filterValue.join(','),
              actives,
            }));
            setExtraQueryValues(prevValues => ({
              ...prevValues,
              status: filterValue.join(','),
              actives,
            }));
          } else {
            setExtraQuery(value => ({
              ...value,
              status: undefined,
              actives: false,
            }));
            setExtraQueryValues(prevValues => ({
              ...prevValues,
              status: undefined,
              actives: false,
            }));
          }

          return rows;
        },
      },
      {
        id: 'channel',
        Header: <FormattedMessage id="channel" />,
        accessor: row => row.channel.description,
        width: 120,
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `${columnId}.description:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind
              ? undefined
              : `${columnId}.description:${kind.toUpperCase()}`,
          }));
        },
        minWidth: 120,
        Cell: ({ value, state }) => (
          <HighlightText text={value} subtext={state.globalFilter} />
        ),
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={channelOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          setExtraQuery(value => ({
            ...value,
            channel:
              filterValue?.length > 0 ? filterValue.join(',') : undefined,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            channel:
              filterValue?.length > 0 ? filterValue.join(',') : undefined,
          }));

          return rows;
        },
      },
      {
        id: 'promisedDelivery',
        Header: <FormattedMessage id="promised-delivery-date" />,
        accessor: data => new Date(data.promisedDeliveryDateTime),
        minWidth: 150,
        width: 200,
        maxWidth: 200,
        sortType: 'datetime',
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `promisedDeliveryDateTime:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind
              ? undefined
              : `promisedDeliveryDateTime:${kind.toUpperCase()}`,
          }));
        },
        Cell: ({ value, inline, state }: CellProps<Order, Date>) => {
          const date = dayjs(value);

          if (inline) {
            return (
              <HighlightText
                text={dayjsCalendar(date)}
                subtext={state.globalFilter}
              />
            );
          }

          return (
            <>
              <Whisper
                trigger={['click', 'hover', 'active']}
                placement="bottomStart"
                speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
                <div className="text-sm font-light first-letter:capitalize">
                  <HighlightText
                    text={dayjsCalendar(date)}
                    subtext={state.globalFilter}></HighlightText>
                </div>
              </Whisper>
            </>
          );
        },
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <DateRangePicker
              id={id}
              name={id}
              size="xs"
              block
              showOneCalendar
              value={filterValue || []}
              onChange={setFilter}
              format="dd/MM/yyyy"
              ranges={dayjsRanges([
                'today',
                'tomorrow',
                'yesterday',
                'last7Days',
                'next7Days',
              ])}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          setExtraQuery(value => ({
            ...value,
            fromPromisedDeliveryDateTime: filterValue?.[0],
            toPromisedDeliveryDateTime: filterValue?.[1],
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            fromPromisedDeliveryDateTime: filterValue?.[0],
            toPromisedDeliveryDateTime: filterValue?.[1],
          }));

          return rows;
        },
      },
      {
        id: 'city',
        Header: <FormattedMessage id="city" />,
        accessor: row => row.shippingAddress.city.name,
        width: 120,
        maxWidth: 120,
        minWidth: 120,
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `shippingAddress.city.name:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind
              ? undefined
              : `shippingAddress.city.name:${kind.toUpperCase()}`,
          }));
        },
        filter: (row, _, filterValue) => {
          filterValue = `${filterValue}`;

          setExtraQuery(value => ({
            ...value,
            city: filterValue.trim() ? filterValue : undefined,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            city: filterValue.trim() ? filterValue : undefined,
          }));
          return row;
        },
      },
      {
        Header: <FormattedMessage id="total" />,
        accessor: 'totalAfterTax',
        minWidth: 80,
        width: 80,
        maxWidth: 80,
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
          }));
        },
        Filter: () => null,
        Cell: ({ value }) => (
          <FormattedNumberParts
            value={value}
            maximumFractionDigits={2}
            minimumFractionDigits={2}>
            {parts => (
              <span className="font-light text-base">
                <span className="mr-1 select-none">$</span>
                {parts.slice(0, -1).reduce((str, part) => str + part.value, '')}
                <span className="text-xs">{parts.at(-1)!.value}</span>
              </span>
            )}
          </FormattedNumberParts>
        ),
      },
      {
        id: 'totalUnits',
        Header: <FormattedMessage id="units" />,
        accessor: 'totalUnits',
        minWidth: 40,
        width: 40,
        maxWidth: 40,
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
          }));
        },
        Cell: ({ value, state, inline }) =>
          inline ? (
            <HighlightText text={value} subtext={state.globalFilter} />
          ) : (
            <div className="font-light text-center">
              <HighlightText text={value} subtext={state.globalFilter} />
            </div>
          ),
        Filter: () => null,
      },
      {
        id: 'invoiceId',
        Header: <FormattedMessage id="invoiceId" />,
        accessor: 'invoiceId',
        width: 100,
        minWidth: 100,
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
          }));
        },
        Cell: ({ value, state, inline }) =>
          inline ? (
            <HighlightText text={value} subtext={state.globalFilter} />
          ) : (
            <div className="font-light text-center">
              <HighlightText text={value} subtext={state.globalFilter} />
            </div>
          ),
        filter: (row, _, filterValue) => {
          filterValue = `${filterValue}`.trim();

          setExtraQuery(value => ({
            ...value,
            // invoiceId: filterValue === '0' ? 'undefined' : undefined,
            invoiceId: filterValue.trim() ? filterValue : undefined,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            invoiceId: filterValue.trim() ? filterValue : undefined,
          }));

          return row;
        },
      },
      {
        id: 'tags',
        Header: <FormattedMessage id="tags" />,
        accessor: row => row.tags,
        width: 30,
        disableSortBy: true,
        Cell: ({ value, state }) => {
          if (value || value?.length > 0) {
            return <TagFigure tags={value} />;
          }
          return null;
        },
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;
          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={tagOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue: string[]) => {
          setExtraQuery(value => ({
            ...value,
            tags: filterValue?.length > 0 ? filterValue.join(',') : undefined,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            tags: filterValue?.length > 0 ? filterValue.join(',') : undefined,
          }));

          return rows;
        },
      },
      {
        id: '$actions',
        accessor: 'id',
        disableFilters: true,
        disableGlobalFilter: true,
        disableSortBy: true,
        minWidth: 110,
        width: 110,
        maxWidth: 110,
        Cell: ({ row }) => {
          const [open, setOpen] = useState(false);

          const loadItems = () =>
            dispatch(
              loadItemsEditOrder({
                action: 'EDIT',
                order: row.original,
                filters: { populateData: true },
              }),
            );

          const copyOrder = () =>
            dispatch(
              loadItemsEditOrder({
                action: 'COPY',
                order: row.original,
                filters: { populateData: true },
              }),
            );

          const getOrderDetails = () =>
            dispatch(
              getOrderTracking({
                populateData: true,
                action: 'ORDER_TRACKING',
                orderIds: [row.original.id],
              }),
            );

          const updateOrderStatus = () =>
            dispatch(openUpdateOrderStatus(row.original));

          const triggerInvoice = (action: string) => {
            const data: InvoiceParams = {
              action,
              orderId: row.original.id,
              statusId: getDocumentCreationStatus('INVOICE'),
            };

            dispatch(triggerInvoicing(data));
          };

          const triggerPurchaseOrderCreation = () => {
            const data: InvoiceParams = {
              action: 'purchase',
              orderId: row.original.id,
              statusId: getDocumentCreationStatus('PO_CREATED'),
            };

            dispatch(triggerOrderEvent(data));
          };

          const openInvoiceURL = () => {
            dispatch(
              getOrderInvoiceURL({
                id: row.original.id,
                windowFeatures: WINDOW_FEATURES,
                erp: loggingParamsRest.erp,
                docType: 'INVOICE',
              }),
            );
          };

          const openPurchaseOrderURL = () => {
            dispatch(
              getOrderInvoiceURL({
                id: row.original.id,
                docType: 'PURCHASE_ORDER',
                windowFeatures: WINDOW_FEATURES,
                erp: loggingParamsRest.erp,
              }),
            );
          };

          const openCreditNote = () => {
            setOpenModalCreditNote(true)
            setOrderActive(row.original)
          };

          const tagOrder = () => {
            dispatch(
              updateSelectedTags({
                orderId: row.original.id,
                tags:
                  row.original.tags && row.original.tags.map(tag => tag.text),
              }),
            );
            dispatch(setUpdateTagsVisible(true));
          };

          return (
            <div>
              <IconButton
                title="Ver detalles"
                size="sm"
                icon={<InfoIcon />}
                onClick={getOrderDetails}
              />
              <IconButton
                title="Cambiar estado"
                size="sm"
                icon={<UpdateIcon />}
                onClick={updateOrderStatus}
              />
              <Whisper
                trigger="none"
                placement="left"
                open={open}
                onOpen={() => setOpen(true)}
                onBlur={() => setOpen(false)}
                speaker={
                  <Popover className="p-0" arrow>
                    <Dropdown.Menu>
                      <Dropdown.Item onMouseDown={tagOrder}>
                        <FormattedMessage id="action.to-tag" />
                      </Dropdown.Item>
                      {(constants.COMPANY_NO_COPY !== companyId) && !companyData?.inventoryConfig?.controlsInventory && (
                        <Dropdown.Item onMouseDown={copyOrder}>
                          <FormattedMessage id="action.copy" />
                        </Dropdown.Item>
                      )}
                      {validateUpdateOrders(
                        permissions,
                        deliveryHoursAhead,
                        row.original.promisedDeliveryDateTime,
                      ) && (
                          <Dropdown.Item onMouseDown={loadItems}>
                            <FormattedMessage id="action.edit" />
                          </Dropdown.Item>
                        )}
                      {!row.original.invoiceId &&
                        loggingParamsRest.erp &&
                        ['INVOICE', 'NO_ACTION'].includes(
                          row.original.actionOnShipment,
                        ) && (
                          <Dropdown.Item
                            onMouseDown={() => triggerInvoice('invoice')}>
                            <FormattedMessage id="action.invoice" />
                          </Dropdown.Item>
                        )}
                      {row.original.purchaseOrderId &&
                        !row.original.invoiceId &&
                        loggingParamsRest.erp &&
                        row.original.actionOnShipment === 'PURCHASE_ORDER' && (
                          <Dropdown.Item
                            onMouseDown={() => triggerInvoice('invoice')}>
                            <FormattedMessage id="action.invoice" />
                          </Dropdown.Item>
                        )}
                      {row.original.actionOnShipment === 'PURCHASE_ORDER' &&
                        !row.original.purchaseOrderId && (
                          <Dropdown.Item
                            onMouseDown={() => triggerPurchaseOrderCreation()}>
                            <FormattedMessage id="action.remission" />
                          </Dropdown.Item>
                        )}
                      {row.original.invoiceId && loggingParamsRest.erp && (
                        <Dropdown.Item onMouseDown={openInvoiceURL}>
                          <FormattedMessage id="action.view-invoice" />
                        </Dropdown.Item>
                      )}
                      {row.original.purchaseOrderId && loggingParamsRest.erp && (
                        <Dropdown.Item onMouseDown={openPurchaseOrderURL}>
                          <FormattedMessage id="action.view-purchase-order" />
                        </Dropdown.Item>
                      )}
                      {row.original.invoiceId && !row.original?.creditNotes?.length &&(
                        <Dropdown.Item onMouseDown={openCreditNote}>
                          <FormattedMessage id="action.create-credit-note" />
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Popover>
                }>
                <IconButton
                  className="shadow-md bg-gray-50 ring-1 ring-gray-300 text-gray-600 focus:text-gray-600"
                  icon={<MoreIcon />}
                  circle
                  appearance="subtle"
                  size="sm"
                  onClick={() => setOpen(v => !v)}
                />
              </Whisper>
            </div>
          );
        },
      },
      {
        id: 'paymentMethod',
        Header: <FormattedMessage id="payment" />,
        accessor: row => row.paymentMethod.description,
        width: 120,
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `${columnId}.description:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind
              ? undefined
              : `${columnId}.description:${kind.toUpperCase()}`,
          }));
        },
        minWidth: 120,
        Cell: ({ value, state }) => (
          <HighlightText text={value} subtext={state.globalFilter} />
        ),
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={paymentOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          setExtraQuery(value => ({
            ...value,
            paymentMethod:
              filterValue?.length > 0 ? filterValue.join(',') : undefined,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            paymentMethod:
              filterValue?.length > 0 ? filterValue.join(',') : undefined,
          }));

          return rows;
        },
      },
      {
        id: 'actualDelivery',
        Header: <FormattedMessage id="delivery-date" />,
        accessor: data => new Date(data.actualDeliveryDateTime),
        minWidth: 150,
        width: 200,
        maxWidth: 200,
        sortType: 'datetime',
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `actualDeliveryDateTime:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind
              ? undefined
              : `actualDeliveryDateTime:${kind.toUpperCase()}`,
          }));
        },
        Cell: ({ value, inline, state }: CellProps<Order, Date | string>) => {
          if (value.toString() === "Invalid Date") {
            return (
              <HighlightText
                text={"-"}
                subtext={state.globalFilter}
              />
            );
          }
          const date = dayjs(value);


          if (inline) {
            return (
              <HighlightText
                text={dayjsCalendar(date)}
                subtext={state.globalFilter}
              />
            );
          }

          return (
            <>
              <Whisper
                trigger={['click', 'hover', 'active']}
                placement="bottomStart"
                speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
                <div className="text-sm font-light first-letter:capitalize">
                  <HighlightText
                    text={dayjsCalendar(date)}
                    subtext={state.globalFilter}></HighlightText>
                </div>
              </Whisper>
            </>
          );
        },
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <DateRangePicker
              id={id}
              name={id}
              size="xs"
              block
              showOneCalendar
              value={filterValue || []}
              onChange={setFilter}
              format="dd/MM/yyyy"
              ranges={dayjsRanges([
                'today',
                'tomorrow',
                'yesterday',
                'last7Days',
                'next7Days',
              ])}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          setExtraQuery(value => ({
            ...value,
            fromActualDeliveryDateTime: filterValue?.[0],
            toActualDeliveryDateTime: filterValue?.[1],
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            fromActualDeliveryDateTime: filterValue?.[0],
            toActualDeliveryDateTime: filterValue?.[1],
          }));

          return rows;
        },
      },
      {
        id: 'invoicedDate',
        Header: <FormattedMessage id="invoice-date" />,
        accessor: data => new Date(data.invoicedDateTime),
        minWidth: 150,
        width: 200,
        maxWidth: 200,
        sortType: 'datetime',
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `invoicedDateTime:${kind.toUpperCase()}`,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            orderBy: !kind
              ? undefined
              : `invoicedDateTime:${kind.toUpperCase()}`,
          }));
        },
        Cell: ({ value, inline, state }: CellProps<Order, Date | string>) => {
          if (value.toString() === "Invalid Date") {
            return (
              <HighlightText
                text={"-"}
                subtext={state.globalFilter}
              />
            );
          }

          const date = dayjs(value);
          if (inline) {
            return (
              <HighlightText
                text={dayjsCalendar(date)}
                subtext={state.globalFilter}
              />
            );
          }

          return (
            <>
              <Whisper
                trigger={['click', 'hover', 'active']}
                placement="bottomStart"
                speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
                <div className="text-sm font-light first-letter:capitalize">
                  <HighlightText
                    text={dayjsCalendar(date)}
                    subtext={state.globalFilter}></HighlightText>
                </div>
              </Whisper>
            </>
          );
        },
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <DateRangePicker
              id={id}
              name={id}
              size="xs"
              block
              showOneCalendar
              value={filterValue || []}
              onChange={setFilter}
              format="dd/MM/yyyy"
              ranges={dayjsRanges([
                'today',
                'tomorrow',
                'yesterday',
                'last7Days',
                'next7Days',
              ])}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          setExtraQuery(value => ({
            ...value,
            fromInvoicedDateTime: filterValue?.[0],
            toInvoicedDateTime: filterValue?.[1],
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            fromInvoicedDateTime: filterValue?.[0],
            toInvoicedDateTime: filterValue?.[1],
          }));

          return rows;
        },
      },
      {
				id: 'createdAt',
				Header: <FormattedMessage id="createdAt" />,
				accessor: data => new Date(data.createdAt),
				minWidth: 150,
				width: 200,
				maxWidth: 200,
				sortType: 'datetime',
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `createdAt:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
							? undefined
							: `createdAt:${kind.toUpperCase()}`,
					}));
				},
				Cell: ({ value, inline, state }: CellProps<Order, Date>) => {
					if (value.toString() === "Invalid Date") {
						return null
					}
					const date = dayjs(value);
					if (inline) {
						return (
							<HighlightText
								text={dayjsCalendar(date)}
								subtext={state.globalFilter}
							/>
						);
					}

					return (
						<>
							<Whisper
								trigger={['click', 'hover', 'active']}
								placement="bottomStart"
								speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
								<div className="text-sm font-light first-letter:capitalize">
									<HighlightText
										text={dayjsCalendar(date)}
										subtext={state.globalFilter}></HighlightText>
								</div>
							</Whisper>
						</>
					);
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<DateRangePicker
							id={id}
							name={id}
							size="xs"
							block
							showOneCalendar
							value={filterValue || []}
							onChange={setFilter}
							format="dd/MM/yyyy"
							ranges={dayjsRanges([
								'today',
								'tomorrow',
								'yesterday',
								'last7Days',
								'next7Days',
							])}
						/>
					);
				},
				filter: (rows, _, filterValue) => {
					setExtraQuery(value => ({
						...value,
						fromCreatedAt: filterValue?.[0],
						toCreatedAt: filterValue?.[1],
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						fromCreatedAt: filterValue?.[0],
						toCreatedAt: filterValue?.[1],
					}));

					return rows;
				},
			},
      {
				id: 'updatedAt',
				Header: <FormattedMessage id="updatedAt" />,
				accessor: row => row.updatedAt,
				minWidth: 200,
				width: 250,
				maxWidth: 250,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `updatedAt:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `updatedAt:${kind.toUpperCase()}`,
          }));
				},
				Cell: ({ value, inline, state }: CellProps<Order, Date>) => {
					if (value.toString() === "Invalid Date") {
						return null
					}
					const date = dayjs(value);
					if (inline) {
						return (
							<HighlightText
								text={dayjsCalendar(date)}
								subtext={state.globalFilter}
							/>
						);
					}

					return (
						<>
							<Whisper
								trigger={['click', 'hover', 'active']}
								placement="bottomStart"
								speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
								<div className="text-sm font-light first-letter:capitalize">
									<HighlightText
										text={dayjsCalendar(date)}
										subtext={state.globalFilter}></HighlightText>
								</div>
							</Whisper>
						</>
					);
				},
				Filter({ column }) {
				const { id, filterValue, setFilter } = column;
				return (
					<DateRangePicker
						id={id}
						name={id}
						size="xs"
						block
            showOneCalendar
						placement="auto"
						value={filterValue}
						onChange={dates => {
							setFilter(dates);
						}}
						format="dd/MM/yyyy HH:mm:ss"
						ranges={dayjsRanges([
							'today',
							'tomorrow',
							'yesterday',
							'last7Days',
							'next7Days',
						])}
					/>
				);
			},
				filter: (rows, _, filterValue) => {
					setExtraQuery(value => ({
						...value,
						fromUpdatedAt: filterValue?.[0],
						toUpdatedAt: filterValue?.[1],
					}));
					setExtraQueryValues(value => ({
						...value,
						fromUpdatedAt: filterValue?.[0],
						toUpdatedAt: filterValue?.[1],
					}));

					return rows;
				},
			},
    ],
    [statusOptions, paymentOptions, channelOptions, tagOptions, dispatch],
  );
  const rowContent = useCallback<DataGridRowContent<Order>>(
    (index, row, { viewport }) => {
      if (viewport.isWide) return;

      const cells = cellsById(row.cells);

      return (
        <div
          className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}>
          <div className="flex flex-col flex-nowrap flex-1">
            <div className="mb-1 flex items-center text-xs text-gray-500">
              <span className="pr-2 -ml-1">
                {cells.$rowSelect.render('Cell')}
              </span>
              <span className="flex flex-row">
                #{cells.orderNumber.render('Cell')}
              </span>
              <span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
              <span>
                {cells.promisedDelivery.render('Cell', { inline: true })}
              </span>
            </div>

            <span className="mb-0.5 text-lg font-normal text-gray-700">
              {cells.client.render('Cell')}
            </span>

            <div className="mb-0.5 text-gray-500">
              <span className="text-xs mr-2">
                {cells.status.render('Cell')}
              </span>
              <span className="text-sm">
                {cells.paymentMethod.render('Cell')}
              </span>
              <span>
                {cells.status.row.original.tags && (
                  <TagFigure tags={cells.status.row.original.tags} />
                )}
              </span>
            </div>
          </div>
          <div className="flex flex-col flex-nowrap items-end">
            <span className="flex-1">{cells.totalAfterTax.render('Cell')}</span>
            <div className="mb-1">
              <span className="text-sm">
                {cells.totalUnits.render('Cell', { inline: true })}
              </span>
              <span className="ml-0.5 lowercase text-sm text-gray-500">
                <FormattedPlural
                  value={cells.totalUnits.value}
                  one={<FormattedMessage id="unit" />}
                  other={<FormattedMessage id="units" />}
                />
              </span>
            </div>
            <span>{cells.$actions.render('Cell')}</span>
          </div>
        </div>
      );
    },
    [],
  );

  const topToolbar = useCallback<DataGridToolbar<Order>>(
    ({ filteredFlatRows, selectedData, rows, columns }) => {
      const statusColumn = columns.find(({ id }) => id === 'status');

      const checked = pendingStatusList.every(id =>
        statusColumn.filterValue?.includes(id),
      );

      const onChange = (checked: boolean) => {
        if (checked) {
          statusColumn?.setFilter(pendingStatusList);
          setFilterByStatus(true);
          setExtraQuery(value => ({
            ...value,
            status: pendingStatusList.join(','),
            actives: true,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            status: pendingStatusList.join(','),
            actives: true,
          }));
        } else {
          statusColumn?.setFilter([]);
          setFilterByStatus(false);
          setExtraQuery(value => ({
            ...value,
            status: undefined,
            actives: false,
          }));
          setExtraQueryValues(prevValues => ({
            ...prevValues,
            status: undefined,
            actives: false,
          }));
        }
      };

      return (
        <>
          <div className="flex items-center gap-2 text-sm">
            <Toggle
              checked={checked && filterByStatus}
              onChange={onChange}></Toggle>{' '}
            Ordenes pendientes
          </div>
          <IconButton
            className="ml-auto mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<PlusIcon />}
            title="Crear orden"
            onClick={redirectCreateOrder}
          />
          <IconButton
            className="mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<FaFileInvoiceDollar className="rs-icon text-gray-400" />}
            title={$t({ id: 'route.consolidated-billing' })}
            onClick={() => handleOnClickBilling(selectedData)}>
          </IconButton>
          <IconButton
            className="mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            title={$t({ id: 'route.multiple-billing' })}
            onClick={() => handleOnClickMultiple(selectedData)}
            icon={
              <FaFileInvoiceDollar className="rs-icon text-blue-400" />
            }></IconButton>
          <Divider vertical className="rs-divider-vertical" />
          <IconButton
            className="mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<FaFileExcel className="rs-icon text-green-500" />}
            title="Exportar a csv"
            onClick={() => handleOnClickExportCSV()}></IconButton>
          <IconButton
            className="mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            title="Exportar a PDF"
            onClick={() => handleOnClickPDF(selectedData)}
            icon={<FaFilePdf className="rs-icon text-red-400" />}></IconButton>
        </>
      );
    },
    [filterByStatus],
  );

  const status = statusList
    .filter(status => !status.automatic)
    .map(item => ({
      label: item.description,
      value: item.id,
      listingOrder: item.listingOrder,
      role: item.role,
    }));

  return (
    <div className="h-screen flex flex-col pb-1">
      <PageHeader title={<FormattedMessage id="orders" />} />
      {orderActive?.invoiceId && (
        <div>
          <ModalCreditNote
            visible={openModalCreditNote}
            onClose={onCloseModalCreditNote}
            ordersSelected={orderActive}
          />
        </div>
      )}
      {openExportData && (
        <ModalExportData
          title={<FormattedMessage id="export-data-orders" />}
          visible={openExportData}
          onClose={() => {
            setOpenExportData(false)
          }}
          submitExport={submitExport}
          disabled={virtualArray?.ref?.length > maxNumberDownloadAllowed}
          pages={virtualArray?.ref?.length / maxNumberDownloadAllowed}
        />
      )}
      {ordersSelected && (
        <div>
          <ModalConsolidatedBilling
            visible={modalConsolidatedBillingOpen}
            status={status}
            statusList={statusList}
            onClose={onCloseModalConsolidatedBilling}
            ordersSelected={ordersSelected}
          />
        </div>
      )}
      {ordersSelected && (
        <div>
          <ModalMultipleBilling
            visible={modalMultipleBillinggOpen}
            status={status}
            statusList={statusList}
            onClose={onCloseModalMultipleBilling}
            ordersSelected={ordersSelected}
          />
        </div>
      )}
      <DataGrid
        className="flex-1"
        data={virtualArray.ref}
        totalCount={virtualArray.ref.length}
        rangeChanged={setViewRange}
        columns={columns}
        selectable
        rowContent={rowContent}
        topToolbar={topToolbar}
        // initialState={{ hiddenColumns }}
        globalFilter={globalFilter}
        overscan={0}
        increaseViewportBy={500}
        autoResetFilters={false}
        autoResetSelectedRows={false}
        autoResetSortBy={false}
        loading={result.isFetching}
      />
      {orderTrackingVisible && (
        <OrderTrackingContainer
          visible={orderTrackingVisible}
          history={false}
        />
      )}
      {updateStatusContainerVisible && (
        <UpdateOrderStatusContainer visible={updateStatusContainerVisible} />
      )}
      {updateTagsVisible && <AssignOrderTagContainer />}
      <AuthModalComponent
        open={authModalVisible.open}
        handleClose={handleCloseModal}
      />
    </div>
  );
};

export default ListOrders;
